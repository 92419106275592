import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Option } from '../../classes/option';
import { environment } from '../../../../environments/environment';
import { ServiceResponse } from '../../classes/service-response';

@Injectable()
export class AutocompleteSelectService {
  constructor (private readonly http: HttpClient) { }

  search<T>(url: string, request: T): Observable<ServiceResponse<Option[]>> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<ServiceResponse<Option[]>>(environment.baseApiUrl + url, JSON.stringify(request), options)
      .pipe(tap((response: ServiceResponse<Option[]>) => {
        if (response == null) return [];
        return response;
      }));
  }
}
