<form class="form" [formGroup]="parentForm">
    <div class="filter-control-wrapper">
        <div class="filter-control-wrapper__header ">
            <div class="d-flex justify-content-between"> 
                <h3 class="my-auto">{{label}}</h3>
                <mat-icon class="d-sm-block d-md-none" (click)="toggle()">keyboard_arrow_down</mat-icon>
            </div>
            <span class="selected-values d-sm-block d-md-none">{{filter.value}}</span>
        </div>
        <div class="filter-control-wrapper__content" [(ngbCollapse)]="isCollapsed">
            <div class="position-relative form-floating">
                
                <input id="{{id}}" type="text" formControlName="{{id}}" name="{{id}}"
                    class="form-control form__input form__input--border" placeholder="" #filter />
                    <label class="form__label p5" for="{{id}}">{{placeHolder}}</label>
                <mat-icon *ngIf="filter.value.length > 0" (click)="onClear()" class="input-icon" svgIcon="clearInputIcon"
                    matSuffix></mat-icon>
                    <mat-icon *ngIf="showError()" class="input-icon input-icon--error"  svgIcon="inputErrorIcon"  matSuffix></mat-icon>
            </div>
        </div>
    </div>
</form>