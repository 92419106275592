import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '../services/logger/logger.service';

@Injectable()
export class HttpErrorInteceptor implements HttpInterceptor {
  constructor (private readonly logger: LoggerService) {}

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          this.logger.error('HttpErrorInterceptor Error:', err.error.message);

          // return empty response and handle error accordingly.
          return of(new HttpResponse({ body: { success: false, errorCode: 333 } })); // as ServiceResponse
        }
        return throwError(() => err);
      })
    );
  }
}
