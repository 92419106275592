import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ApplicationInsightsService } from 'src/app/shared/services/application-insights.service';
import { GlobalFaqService } from '../global-faq/global-faq.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'anthem-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('sideNav', { static: false }) sideNav?: MatSidenav | undefined;
  showGlobalFaq = false;
  constructor (private readonly appInsights: ApplicationInsightsService, private readonly faqService: GlobalFaqService) {
    this.faqService.toggleMenu$.pipe(untilDestroyed(this)).subscribe((isShown) => {
      this.showGlobalFaq = isShown;
      this.sideNav?.toggle();
    });
  }

  ngOnInit (): void {
    this.appInsights.trackEvent('Application Started');
  }
}
