import { Directive, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

export class DialogConfig {
  id = '';
  width = '';
  height = '';
  data: any = undefined;
  disableClose = true;
  panelClass = 'anthem-dialog';

  constructor (dialogId: string, width: number, height: number, data: any = undefined, fullWidth = false) {
    this.id = dialogId;
    if (fullWidth) {
      this.width = '100vw';
      this.height = '100vh';
    } else {
      if (width === -1) {
        this.width = 'auto';
      } else {
        this.width = width.toString() + 'px';
      }
      if (height === -1) {
        this.height = 'auto';
      } else {
        this.height = height.toString() + 'px';
      }
    }

    if (data != null) {
      this.data = data;
      this.data.dialogId = dialogId;
    } else {
      this.data = { dialogId: this.id };
    }
  }
}

@UntilDestroy()
@Directive()
export abstract class DialogBase<T> {
  readonly dialogRef: MatDialogRef<T>;
  readonly dialog: MatDialog;
  dialogId: string;

  constructor (injector: Injector, dialogRef: MatDialogRef<T>, dialogId: string) {
    this.dialogRef = dialogRef;
    this.dialog = injector.get(MatDialog);
    this.dialogId = dialogId;
  }

  onClose (): void {
    this.dialogRef?.close();
  }
}
