import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Card } from './card-view.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';

@Component({
  selector: 'card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.scss']
})
export class CardViewComponent {
  public isCollapsed = false;
  @Input() cardData?: Card;
  @Output() buttonClicked = new EventEmitter<number>();

  constructor (private readonly breakpointObserver: BreakpointObserver, private readonly router: Router, private readonly userConfigService: UserConfigurationService) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isCollapsed = true;
      } else {
        this.isCollapsed = false;
      }
    });
  }

  isArray (value: any): boolean {
    return Array.isArray(value);
  }

  navigateToDetails (): void {
    this.router.navigate([`/${this.userConfigService.getClientKey()}/provider-details`]);
  }

  onButtonClicked (value: number): void {
    this.buttonClicked.emit(value);
  }

  getImagePath (path: string): string {
    return `../../../../../assets/${path}`;
  }
}
