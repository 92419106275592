<section class="page">
        <!-- Header  -->
        <div class="container-fluid">
            <div class="row page__header--gray page__header--border-top justify-content-center">
                <div class="col-lg-10 col-sm-12 d-flex">
                    <h2 class="heading-primary mx-auto">Disclaimer</h2>
                </div>
            </div>
        </div>
</section>

<section class="disclaimer-wrapper">
    <div class="container-fluid">
        <div class="row">
           <div class="col-8 mx-auto">
              <p class="p4">Please carefully read the following important information.</p>
              <p class="p4">This on-line directory of providers is being provided for general information purposes only. Please refer to the applicable plan for information on plan benefits, conditions, limitations, exclusions, and other important provisions. You may also call the toll free phone number on your ID card for further assistance. Please verify with the provider you select that the provider is currently participating in the Anthem Workers' Compensation network at the location in which you are seeking care.</p>
              <p class="p4">The listing of providers does not constitute a recommendation or endorsement of any physician, provider group, hospital/facility, or other provider.</p>
              <p class="p4">We have made a commercially reasonable effort to ensure the accuracy of this on-line directory of providers. However, we make no representation, warranty, guarantee, or assurance of any kind, express or implied, as to the accuracy, completeness, timeliness, or other qualities of this information. Provider participation varies significantly depending on the type of your plan and coverage. Please carefully review your Evidence of Coverage (EOC), Certificate of Coverage (COC), and/or other plan documents for more information specific to your benefit plan, or call the toll-free customer service number located on your ID Card.</p>
              <p class="p4">The physicians, provider groups, hospitals/facilities, and other providers that are part of the network of providers referred to by this directory are independent contractors who exercise independent judgment and over whom Anthem Workers' Compensation has no control or right of control, and are not agents, employees, franchisees, joint ventures, or partners of Anthem Workers' Compensation. If you, or a covered family member, select a provider in the network, either directly or by referral from another provider, you are seeking care from that provider, not from Anthem Workers' Compensation. The provider exercises independent professional judgment. It is your provider's responsibility to discuss treatment options with you, even those that may not be eligible for benefits under your Plan. Anthem Workers' Compensation decisions about whether any services or supplies are covered under your benefit plan are insurance benefit decisions only and are not the provision of medical or health care. Anthem Workers' Compensation is not responsible for, does not provide, and does not hold itself out as the provider of your medical or health care. Only the doctors and/or other healthcare practitioners who treat you can provide medical and/or health care and only those doctors and/or other healthcare practitioners are responsible for any negligence in providing your care. If a service or supply is not eligible for benefits under your plan, you and your provider are free to proceed with that service or supply knowing that benefits are not available under your plan.</p>
              <p class="p4">Some plans require prior authorization for certain services and/or supplies. Please refer to your EOC, COC, and/or other plan documents for more information regarding any authorization processes applicable to your plan. Failure to do so may result in additional costs to you including, but not limited to, deductibles, co-payments, co-insurance or that the services may be excluded partially or entirely by your benefit plan. You may also call the toll free phone number on your ID card for further assistance.</p>
           </div>
        </div>
    </div>
</section>