import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';

@Component({
  selector: 'sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent {
  @Input() headerName = '';
  @Input() linkHref = '';
  @Input() linkText = '';
  @Input() temp: TemplateRef<any> | undefined;

  constructor (private readonly configService: UserConfigurationService, private readonly router: Router) {}

  navigateToUrl (): void {
    const clientKey = this.configService.getClientKey();
    this.router.navigateByUrl(`/${clientKey}/${this.linkHref}`);
  }
}
