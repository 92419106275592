import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormErrorMessages } from '../../classes/form-error-messages';

@Component({
  selector: 'gm-autocomplete',
  templateUrl: './gm-autocomplete.component.html',
  styleUrls: ['./gm-autocomplete.component.scss']
})

export class GmAutocompleteComponent implements AfterViewInit {
  @Input() addressType = '';
  @Input() parentForm = new FormGroup({});
  @Input() id = '';
  @Output() setAddress = new EventEmitter<any>();
  @ViewChild('addresstext') addresstext: any;
  @Input() placeholder = '';
  errorMessages = new FormErrorMessages();

  ngAfterViewInit (): void {
    this.getAddressAutocomplete();
  }

  private getAddressAutocomplete (): void {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'us' },
      types: ['(regions)']
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      const formControl = this.parentForm.get(this.id);
      const value = place.formatted_address ?? formControl?.value;
      formControl?.setValue(value);
    });
  }

  onClear (): void {
    const formControl = this.parentForm.get(this.id);
    formControl?.setValue('');
  }

  emitAddress (address: Object): void {
    this.setAddress.emit(address);
  }

  showError (): boolean {
    const formControl = this.parentForm.get(this.id);
    return (formControl != null) ? formControl.invalid && formControl.touched : false;
  }
}
