import { LoggerSwitchEnum } from '../app/shared/services/logger/logger.enum';

export const environment = {
  production: false,
  baseApiUrl: 'https://dev-anthemapi.goperspecta.tech',
  password: 'Kg]]qzspwvN#wB4U',
  userName: 'anthemWebApp',
  loggerEnabled: LoggerSwitchEnum.OFF,
  appInsights: {
    key: '49b78ba7-051d-47f1-92ae-858a5706d572'
  }
};
