import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NotificationTypeClassMap, ToastNotificationEvent } from './toast.model';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  toasts: ToastNotificationEvent[] = [];
  toastClass = NotificationTypeClassMap;

  constructor (private readonly toastService: ToastService, private readonly cd: ChangeDetectorRef) {}

  ngOnInit (): void {
    this.setSubscriber();
  }

  setSubscriber (): void {
    this.toastService.events.subscribe((event: ToastNotificationEvent) => {
      this.toasts.push(event);
      this.cd.detectChanges();
    });
  }

  removeNotification (toast: ToastNotificationEvent): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
