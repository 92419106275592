<div class="container-fluid filter-wrapper" [ngClass]="{'filter-popover': showPopover}">
    <div class="refine justify-content-center row">
        <div class="col-lg-10 col-sm-12 d-flex justify-content-between my-auto">
            <div class="button-wrapper d-flex w-100">
                <span class="p5 d-none d-md-block my-auto">{{title}}</span>
                <a (click)="showOverlay()" class="p2 mx-auto my-auto text-white button-text button-text--underlined  d-sm-block d-md-none">{{title}}</a>
                <div class="d-none d-md-block my-auto">
                    <input type="checkbox" id="toggleBtn">
                    <label for="toggleBtn" class="icon rotate align-middle" (click)="toggle()"><mat-icon  aria-hidden="false"
                        aria-label="Chevron Animation">keyboard_arrow_down</mat-icon></label>
                    </div>

                </div>
                <div class="button-wrapper d-flex" *ngIf="showFilters">
                    <button type="button"class="button button--transparent my-auto button--xs me-3 p5 d-none d-md-block">Clear all filters</button>
                    <button type="button"class="button button--white my-auto button--xs me-3 p5 d-none d-md-block">Apply Filters</button>
                    <a  (click)="showOverlay()"><mat-icon class="d-sm-block d-md-none text-white">close</mat-icon></a>
                </div>

        </div>
    </div>
    <div class="row justify-content-center bg-white content pb-3" *ngIf="showFilters && filterTemplate">
        <div class="col-lg-10 col-sm-12" @slideContentAnimation>
            <div class="row animated">
                <ng-container  [ngTemplateOutlet]="filterTemplate"></ng-container>
            </div>
        </div>
    </div>
    <div class="row static-footer d-sm-block d-md-none fixed-bottom" *ngIf="showFilters && filterTemplate">
        <div class="col-12 d-flex justify-content-between pt-3 pb-3">
            <a class="button-text text-white ms-3 my-auto p5">Clear all Filters</a>
            <button type="button" class="button button--white my-auto button--small me-3 p5">Apply Filters</button>
        </div>
    </div>
</div>