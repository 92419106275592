import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild, ChangeDetectorRef, OnInit, Injector, AfterViewInit } from '@angular/core';
import { ProviderResultsService } from './provider-results.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SkeletonTemplateEnum } from '../../shared/skeletons/skeleton-template.component';
import { Card, CardBodySection, CardHeader, Section } from '../../shared/components/card-view/card-view.model';
import { ISearchValueConstraint, SearchBreadcrumbFilterComponent, SearchOptionsEnum } from 'src/app/shared/components/filters/search-breadcrumb-filter/search-breadcrumb-filter.component';
import { ToastNotificationEvent, ToastNotificationTypeEnum } from 'src/app/shared/components/dialogs/toast/toast.model';
import { ToastService } from 'src/app/shared/components/dialogs/toast/toast.service';
import { Router } from '@angular/router';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';
import { ProviderDetails, ProviderFilter } from './provider-results.model';
import { Utility } from 'src/app/shared/classes/utility';
import { DataSourceBase, ResultsViewBase } from '../generic/results/results-view-base';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { SearchRequest } from '../provider-search/provider-search.model';
import { skip } from 'rxjs';
import { SearchService } from '../search/search.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { UntypedFormGroup, FormArray, Validators, FormBuilder, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { CheckBoxSelectedArgs, CheckboxOption, Option, ToggleFilter } from 'src/app/shared/classes/option';

class ProviderResultsDataSource extends DataSourceBase<ProviderFilter, ProviderDetails, ProviderResultsService> {}

@UntilDestroy()
@Component({
  selector: 'provider-results',
  templateUrl: './provider-results.component.html',
  styleUrls: ['./provider-results.component.scss'],
  animations: [
    trigger('animationTrigger', [
      state(
        'expanded',
        style({
          transform: 'rotate(180deg)'
        })
      ),
      transition('collapsed => expanded', animate('300ms ease-in')),
      transition('expanded => collapsed', animate('300ms ease-out'))
    ]),
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate('300' + 'ms ease-in')),
      transition('true => false', animate('300' + 'ms ease-out'))
    ])
  ]
})
export class ProviderResultsComponent extends ResultsViewBase<ProviderResultsDataSource, ProviderFilter, ProviderDetails> implements OnInit, AfterViewInit {
  public skeletonTemplateEnum = SkeletonTemplateEnum;
  @ViewChild('breadcrumbs') breadcrumbs?: SearchBreadcrumbFilterComponent;

  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  genderOptions: Option[] = [];
  languageOptions: CheckboxOption[] = [];
  providerTypeOptions: CheckboxOption[] = [];
  hospitalAffiliationOptions: CheckboxOption[] = [];
  specialtyOptions: CheckboxOption[] = [];
  toggleFilters: ToggleFilter[] = [];
  providers = new ProviderDetails();
  results: Card[] = [];

  constructor (protected override injector: Injector, private readonly breakpointObserver: BreakpointObserver, private readonly formBuilder: FormBuilder, private readonly logger: LoggerService, private readonly router: Router, private readonly configService: UserConfigurationService, private readonly providerResultsService: ProviderResultsService, private readonly toastService: ToastService, public override readonly cd: ChangeDetectorRef, private readonly searchService: SearchService) {
    super(injector, providerResultsService, ProviderFilter);
    this.dataSource = new ProviderResultsDataSource(this.logger, this.providerResultsService, searchService);
  }

  ngAfterViewInit (): void {
    this.setBreadCrumbs();
  }

  ngOnInit (): void {
    this.onInit();
    this.formGroup = this.formBuilder.group({
      facilityName: '',
      phoneNumber: '',
      providerIdentifier: '',
      taxId: '',
      gender: '',
      providerLanguages: new FormArray([]),
      providerTypes: new FormArray([]),
      affiliations: new FormArray([]),
      specialty: new FormArray([]),
      location: ['', Validators.required],
      relatedSpecialties: new FormArray([]),
      fellowship: new FormArray([]),
      practiceFocus: new FormArray([])
    });

    this.configureInputOptions();
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.toggleFilters.forEach(filter => {
          filter.isCollapsed = true;
        });
      } else {
        this.toggleFilters.forEach(filter => {
          filter.isCollapsed = false;
        });
      }
    });
    this.dataSource.connect().pipe(skip(1)).subscribe(data => {
      this.createCards(data);
      this.loading = false;
    });
  }

  configureInputOptions (): void {
    this.configureGenderOptions();
    this.configureLanguageOptions();
    this.configureProviderTypeOptions();
    this.configureHospitalAffiliationOptions();
    this.configureSpecialtyOptions();
    this.configureToggles();
  }

  configureToggles (): void {
    for (let i = 0; i < 12; i++) {
      this.toggleFilters.push(new ToggleFilter(i, true));
    }
  }

  get ordersFormArray (): FormArray {
    return this.formGroup.controls.providerLanguages as FormArray;
  }

  configureSpecialtyOptions (): void {
    this.specialtyOptions = [
      { value: 100, label: 'Specialty 1', checked: false },
      { value: 101, label: 'Specialty 2', checked: false },
      { value: 102, label: 'Specialty 3', checked: false },
      { value: 103, label: 'Specialty 4', checked: false },
      { value: 104, label: 'Specialty 5', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureGenderOptions (): void {
    this.genderOptions.push(new Option('Male', 'm'));
    this.genderOptions.push(new Option('Female', 'f'));
  }

  configureLanguageOptions (): void {
    this.languageOptions = [
      { value: 101, label: 'Spanish', checked: false },
      { value: 102, label: 'Chinese', checked: false },
      { value: 103, label: 'French', checked: false },
      { value: 104, label: 'Korean', checked: false },
      { value: 105, label: 'Viatnamese', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureProviderTypeOptions (): void {
    this.providerTypeOptions = [
      { value: 1314, label: 'Existing Patients Only', checked: false },
      { value: 5657, label: 'By Referral Only', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureHospitalAffiliationOptions (): void {
    this.hospitalAffiliationOptions = [
      { value: 101, label: 'Affiliation 1', checked: false },
      { value: 102, label: 'Affiliation 1', checked: false },
      { value: 103, label: 'Affiliation 1', checked: false },
      { value: 1004, label: 'Affiliation 1', checked: false }
    ] as unknown as CheckboxOption[];
  }

  checkBoxSelection (args: CheckBoxSelectedArgs, id: string): void {
    const control = <UntypedFormArray> this.formGroup.get(id);

    if (args.event?.checked) {
      control.push(new UntypedFormControl(args.option?.value));
    } else {
      const i = control.controls.findIndex(x => x.value === args.option?.value);
      control.removeAt(i);
    }
    console.log(this.formGroup.value);
  }

  toggleFiltersControls (index: number): void {
    this.toggleFilters.forEach(toggle => {
      if (toggle.index === index) {
        toggle.isCollapsed = !toggle.isCollapsed;
      } else {
        toggle.isCollapsed = true;
      }
    });
  }

  resetFilters (): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      if (this.formGroup.controls[key] instanceof UntypedFormArray) {
        this.removeFormArrayValues(this.formGroup.controls[key] as UntypedFormArray);
      } else {
        this.formGroup.controls[key].setValue('');
      }
    });
    this.formGroup.reset();
    this.cd.detectChanges();
  }

  removeFormArrayValues (group: UntypedFormArray): void {
    for (let i = 0; i < group.length; i++) {
      group.removeAt(i);
    }
  }

  setSearchParams (): void {
    this.searchRequest = new SearchRequest({
      searchLocation: '9437 Lake St,Fresno, CA 93722',
      searchType: 'Specialty',
      radius: 10
    });
  }

  setBreadCrumbs (): void {
    const filters = new Map<SearchOptionsEnum, ISearchValueConstraint>();
    filters.set(SearchOptionsEnum.DISTANCE, { value: '15 Miles', canRemove: false });
    filters.set(SearchOptionsEnum.PRACTICE, { value: 'Orthopedic', canRemove: true });
    filters.set(SearchOptionsEnum.STATE, { value: 'NJ', canRemove: false });

    this.breadcrumbs?.setActiveFilters(filters);
  }

  addFilterTest (): void {
    this.breadcrumbs?.addFilter(SearchOptionsEnum.SPECIALTIES, { value: 'neck management', canRemove: false });
    this.toastService.showToast({ type: ToastNotificationTypeEnum.GENERAL, delay: 5000, autoHide: true, title: 'Test', message: 'Filter added' } as ToastNotificationEvent);
  }

  createCards (providers: ProviderDetails[]): void {
    this.results = [];
    for (let i = 0; i < providers.length; i++) {
      const details = providers[i];
      const card = new Card();
      const header = new CardHeader();

      header.title = details.providerName ?? '';
      // header.subTitle = data[i].specialties.join(',');
      header.icon = 'Svg/Anthem_profile_icon_doctor.svg';

      header.addButton('', 1, 'Svg/Anthem_button_icon_share.svg', 'message button', 'button button--green button--small-icon me-2');
      header.addButton('', 2, 'Svg/Anthem__button_comment_icon.svg', 'message button 2', 'button button--green button--small-icon me-2');
      header.addButton('View Profile', 2, '', 'message button 2', 'button button--small button--green d-md-none');

      card.header = header;
      let section = new Section();
      const location = new CardBodySection('Location');
      location.addListItem(details.address ?? '', '', false, false, 0);
      location.addListItem(details.address2 ?? '', '', false, false, 0);
      location.addListItem(`${details.city ?? ''}, ${details.state ?? ''} ${details.zip ?? ''}`, '', false, false, 0);
      location.addListItem(details.county ?? '', '', false, false, 0);
      location.addListItem(`${details.distance ?? ''} miles http://www.google.com/${this.createMapQuery(details.latLong ?? '')}[Get Directions]`, '/Svg/Anthem_card_Icon_location.svg', false, true, 0, '', 'pe-2');
      location.addListItem(Utility.formatPhoneNumber(details.phoneNumber ?? ''), '/Svg/Anthem_Card_Icon_telephone.svg', true, false, 0, 'tel:+', 'pe-2', 'mt-4');
      section.content.push(location);
      card.body.push(section);

      section = new Section();
      const fellowship = new CardBodySection('fellowship');
      fellowship.addListItem('Fellowship 1', '', false, false, 0);
      fellowship.addListItem('Fellowship 2', '', false, false, 0);
      section.content.push(fellowship);

      const practice = new CardBodySection('Practice focus');
      practice.addListItem('Hand', '', false, false, 0);
      practice.addListItem('Wrist', '', false, false, 0);
      practice.addListItem('Forearm surgery', '', false, false, 0);
      section.content.push(practice);
      card.body.push(section);

      section = new Section();
      const gender = new CardBodySection('gender');
      gender.addListItem('Male', '', false, false, 0);
      section.content.push(gender);

      const languages = new CardBodySection('languages');
      languages.addListItem('Korean', '', false, false, 0);
      section.content.push(languages);
      card.body.push(section);

      section = new Section();
      const perks = new CardBodySection('');
      perks.addListItem('By Referral Only', '/Svg/Anthem_card_Icon_Tags.svg', false, false, 0, '');
      perks.addListItem('Existing Patients Only', '/Svg/Anthem_card_Icon_Tags.svg', false, false, 0, '');

      section.content.push(perks);

      const affiliation = new CardBodySection('NETWORK AFFILIATION');
      affiliation.addListItem('Blue Cross of California', '', false, false, 0);
      section.content.push(affiliation);
      card.body.push(section);

      this.results.push(card);
    }
    this.cd.detectChanges();
  }

  goBack (): void {
    const clientKey = this.configService.getClientKey();
    this.router.navigateByUrl(`/${clientKey}/provider-search`);
  }

  search (): void {
    console.log('search clicked', this.formGroup.value);
  }

  createMapQuery (latLong: string | undefined): string {
    if (latLong != null) {
      const data = latLong?.split('|');
      return `maps?q=${data[0]},${data[1]}`;
    } else {
      return '';
    }
  }
}
