import { ChangeDetectorRef, Directive, Injector } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ResultsResponse, ResultsViewOptions } from 'src/app/shared/classes/results-view';
import { ServiceResponse } from 'src/app/shared/classes/service-response';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { SearchRequest } from '../../provider-search/provider-search.model';
import { SearchService } from '../../search/search.service';

export interface IDataService<TFilter, TResults> {
  getDataForCards (options: any): Observable<ServiceResponse<ResultsResponse<TFilter, TResults>>>
}

export interface IResultsView<TFilter> {
  cd: ChangeDetectorRef
  viewOptions: ResultsViewOptions<TFilter>
  searchRequest: SearchRequest
  loading: boolean
}

export interface IDataSource {
  loadResults (component: any): void
  getResults (component: any): Observable<void>
  destroy (): void
}

@Directive()
export abstract class ResultsViewBase<TDataSource extends IDataSource, TFilter, TResults> implements IResultsView<TFilter> {
  viewOptions: ResultsViewOptions<TFilter>;
  dataSource: TDataSource = {} as TDataSource;
  searchRequest: SearchRequest = {} as SearchRequest;
  loading = false;

  readonly dataService: IDataService<TFilter, TResults>;
  readonly cd: ChangeDetectorRef;

  constructor (protected injector: Injector, dataService: IDataService<TFilter, TResults>, TFilterCreator: new () => TFilter) {
    this.cd = injector.get(ChangeDetectorRef);
    this.viewOptions = new ResultsViewOptions<TFilter>(TFilterCreator);
    this.viewOptions.pageSize = 5;
    this.dataService = dataService;
  }

  onInit (): void {
    this.dataSource.loadResults(this);
  }

  onPageChange (page: number): void {
    this.viewOptions.pageNumber = page;
    this.dataSource.loadResults(this);
    window.scrollTo(0, 0);
  }
}

export abstract class DataSourceBase<TFilter, TResults, TDataService extends IDataService<TFilter, TResults>> implements IDataSource {
  protected readonly rows = new BehaviorSubject<TResults[]>([]);

  constructor (private readonly logger: LoggerService, private readonly dataService: TDataService, private readonly searchService: SearchService) {
  }

  destroy (): void {
    this.logger.log('destroy base class');
  }

  connect (): Observable<TResults[]> {
    return this.rows.asObservable();
  }

  loadResults (component: IResultsView<TFilter>): void {
    this.getResults(component).pipe(untilDestroyed(this, 'destroy')).subscribe();
  }

  getResults (component: IResultsView<TFilter>): Observable<void> {
    component.loading = true;
    return this.dataService.getDataForCards({ ...component.viewOptions, ...this.searchService.getSearchRequest() }).pipe(
      map((result: ServiceResponse<ResultsResponse<TFilter, TResults>>): void => {
        if (result == null) { return; }
        this.logger.log(result);
        component.viewOptions.update(result.result.viewOptions);
        component.viewOptions.pageSize = 5;
        this.rows.next(result.result.data);
      })
    );
  }
}
