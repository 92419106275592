import { Component } from '@angular/core';
import { CheckBoxSelectedArgs, CheckboxOption } from 'src/app/shared/classes/option';
import { SearchFilterBaseComponent } from '../search-base-filter.component';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'search-checkbox-list-filter',
  templateUrl: './search-checkbox-list-filter.component.html',
  styleUrls: ['./search-checkbox-list-filter.component.scss']
})
export class SearchCheckboxListFilterComponent extends SearchFilterBaseComponent<CheckboxOption> {
  getValues (): string {
    const displayNames = [] as string[];
    const values = this.parentForm.get(this.id)?.value;
    const selectedOptions = this.options.filter(option => {
      return values.includes(option.value);
    });

    if (selectedOptions.length > 0) {
      selectedOptions.forEach(option => {
        displayNames.push(option.label);
      });
    }

    return displayNames.join(', ');
  }

  override onSelected (event: MatCheckboxChange, option: CheckboxOption): void {
    this.optionSelected.emit(new CheckBoxSelectedArgs(event, option));
    // triggers observable for sorting list.
    this.options.sort(val => {
      return this.parentForm.get(this.id)?.value.includes(val.value) ? -1 : 1;
    });
  }
}
