import { Injectable, isDevMode } from '@angular/core';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AngularPlugin, IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService {
  private readonly appInsights: ApplicationInsights;

  constructor (private readonly router: Router, private readonly title: Title) {
    const angularPlugin = new AngularPlugin();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.key,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router, errorServices: [new CustomErrorHandler()] }
        },
        enableDebug: true,
        loggingLevelConsole: isDevMode() ? 2 : 0,
        correlationHeaderExcludedDomains: ['*.blob.core.windows.net'],
        autoTrackPageVisitTime: true,
        disableDataLossAnalysis: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: false,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true,
        maxAjaxCallsPerView: -1,
        sessionExpirationMs: 900,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags ?? [];
    });
    this.appInsights.trackPageView({
      name: title.getTitle()
    });
  }

  trackEvent (event: string, customProperties?: any): void {
    this.appInsights.trackEvent({ name: event }, customProperties);
  }

  trackException (message: string, customProperties?: any): void {
    this.appInsights.trackException({ exception: { message, url: '', lineNumber: 0, columnNumber: 0, error: undefined, typeName: '' } }, customProperties);
  }
}

/**
 * This is a helper service to output exceptions to console for easier debugging during development. Without this the application insights plugin
 * will swallow the exception after sending it to AI
 */
export class CustomErrorHandler implements IErrorService {
  handleError (error: any): void {
    if (isDevMode()) {
      console.error(error);
    }
  }
}
