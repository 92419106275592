import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'anthem-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor (private readonly router: Router, private readonly titleService: Title) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let substring = event.url.split('/')[2];
        const clientKey = event.url.split('/')[1];
        switch (substring?.toLowerCase()) {
          case 'provider-search':
            substring = 'Provider Search';
            break;
          case 'provider-results':
            substring = 'Provider Results';
            break;
          case 'provider-details':
            substring = 'Provider Details';
            break;
          default:
            if (substring != null && substring.length > 0) {
              substring = substring[0].toUpperCase() + substring.substring(1);
            }
            break;
        }
        if (substring != null && substring.length > 0) this.titleService.setTitle(`Anthem - ${substring} ${clientKey}`);
      }
    });
  }
}
