
<div class="container">
  <h2 mat-dialog-title>{{dialogData.title}}</h2>
  <mat-dialog-content>
    <section>
      <div class="row" [innerHtml]="dialogData.message"></div>
    </section>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <div class="row">
      <div class="col-12">
        <button mat-raised-button *ngFor="let button of dialogData.buttons; let i = index" (click)="onButtonClicked(button.value)">{{button.name}}</button>
      </div>
    </div>
  </mat-dialog-actions>
</div>

