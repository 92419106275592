import { Component, OnInit } from '@angular/core';
import { SkeletonTemplateEnum } from '../../shared/skeletons/skeleton-template.component';

@Component({
  selector: 'provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss']
})
export class ProviderDetailsComponent implements OnInit {
  public loading = true;
  public skeletonTemplateEnum = SkeletonTemplateEnum;

  ngOnInit (): void {
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  }
}
