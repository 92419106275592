<form class="form" [formGroup]="formGroup">
   
    <div class="filter-control-wrapper">
        <div class="filter-control-wrapper__header">
            <div class="d-flex justify-content-between"> 
                <h3 class="my-auto">{{label}}</h3>
                <mat-icon class="d-sm-block d-md-none" (click)="toggle()">keyboard_arrow_down</mat-icon>
            </div>
            <span class="selected-values d-sm-block d-md-none">{{getValues()}}</span>
        </div>

        <div class="filter-control-wrapper__content"  [(ngbCollapse)]="isCollapsed">
            <div class="position-relative">
                <input id="inputFilter" type="text" formControlName="input" name="Filter"
                class="form__input form-control form__input--border" placeholder="{{placeHolder}}" #filter />
                <mat-icon (click)="onClear()" *ngIf="filter.value.length > 0" class="input-icon" svgIcon="clearInputIcon"
                    matSuffix></mat-icon>
            </div>

            <div class="mt-3 checkbox-list">
                <ul>
                    <li *ngFor="let option of filteredStaticOptions | async; let i = index"> 
                        <mat-checkbox disableRipple color="primary" class="checkbox p5" [checked]="isChecked(option.value)"  (change)="onSelected($event, option)">
                            {{option.label}} {{i}}
                        </mat-checkbox></li>
                </ul>
            </div>
        </div>

    </div>
</form>