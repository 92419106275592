import { MatCheckboxChange } from '@angular/material/checkbox';

export class Option {
  displayName = '';
  displayId = '';
  additionalData: any | undefined;

  constructor (displayName: string, displayId: string, additionalData: any | undefined = undefined) {
    this.displayName = displayName;
    this.displayId = displayId;
    this.additionalData = additionalData;
  }
}

export class CheckboxOption {
  value = '';
  label = '';
  checked: boolean;

  constructor (value: string, label: string, checked = false) {
    this.value = value;
    this.label = label;
    this.checked = checked;
  }
}

export class CheckBoxSelectedArgs {
  event: MatCheckboxChange;
  option: CheckboxOption;

  constructor (event: MatCheckboxChange, option: CheckboxOption) {
    this.event = event;
    this.option = option;
  }
}

export class ToggleFilter {
  index = 0;
  isCollapsed = false;

  constructor (index: number, isCollapsed: boolean) {
    this.index = index;
    this.isCollapsed = isCollapsed;
  }
}
