import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [
    MatIconModule
  ],
  exports: [MatIconModule],
  providers: [MatIconRegistry]
})
export class IconModule {
  private readonly path = '../../../assets/Svg/';
  constructor (private readonly domSanitizer: DomSanitizer, public matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon('inputErrorIcon', this.setPath(`${this.path}Icon_Error.svg`));
    this.matIconRegistry.addSvgIcon('clearInputIcon', this.setPath(`${this.path}X_Icon.svg`));
  }

  private setPath (url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
