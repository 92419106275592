import { IViewOptions } from 'src/app/shared/classes/results-view';
import { NoFunctions } from 'src/app/shared/classes/ts-core';

export class ProviderDetails {
  tothpdpyid: number | undefined;
  tierIndex: string | undefined;
  bestSource: string | undefined;
  bestToothFairy: string | undefined;
  distance: number | undefined;
  webId: number | undefined;
  typeWC: boolean | undefined;
  typeGH: boolean | undefined;
  typeAuto: boolean | undefined;
  taxId: string | undefined;
  factFrontLine: number | undefined;
  lastUpdate: string | undefined;
  isVerifiedDate: string | undefined;
  isVerified: boolean | undefined;
  hospitalVerified: boolean | undefined;
  frontline: number | undefined;
  sourceCode: string | undefined;
  providerNote: string | undefined;
  hospitalName: string | undefined;
  providerName: string | undefined;
  providerLastName: string | undefined;
  providerFirstName: string | undefined;
  address: string | undefined;
  address2: string | undefined;
  county: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  phoneNumber: string | undefined;
  phoneNumbers: string | undefined;
  fax: string | undefined;
  facilityName: string | undefined;
  latLong: string | undefined;
  sex: string | undefined;
  degree: string | undefined;
  externalId: number | undefined;
  clientDisplayName: string | undefined;
  providerNotesGlobalId: number | undefined;
  positiveAlert: boolean | undefined;
  hideProvider: boolean | undefined;
  docTreatsWC: boolean | undefined;
  docTreatsWCRestrictions: boolean | undefined;
  excludedSpecialtiesCSV: undefined;
  practiceFocus: undefined;
  docTreatsWCRestrictionsReferralOnly: boolean | undefined;
  docTreatsWCRestrictionsRecordReview: boolean | undefined;
  byReferralFinalAnswer: boolean | undefined;
  specialties: object[] | undefined;
  medicalGroup: string | undefined;
  licenseNumber: string | undefined;

  constructor (init?: Partial<Pick<ProviderDetails, NoFunctions<ProviderDetails>>>) {
    Object.assign(this, init);
  }
}

export class ProviderSearchResults {
  viewOptions: IViewOptions | undefined;
  data: ProviderDetails[] = [];
}

export class ProviderFilter {
  provider = '';
}

export class Pagination {
  pageCount = 0;
  itemCount = 0;
  pageSize = 0;
  pageItemCount = 0;
  pageNumber = 0;
}
