import { Component } from '@angular/core';
import { Option } from 'src/app/shared/classes/option';
import { SearchFilterBaseComponent } from '../search-base-filter.component';

@Component({
  selector: 'app-search-radio-filter',
  templateUrl: './search-radio-filter.component.html',
  styleUrls: ['./search-radio-filter.component.scss']
})
export class SearchRadioFilterComponent extends SearchFilterBaseComponent<Option> {
  getValues (): string {
    const value = this.options.filter(option => {
      return option.displayId === this.parentForm.get(this.id)?.value;
    }).map(value => value.displayName)[0];

    return value;
  }
}
