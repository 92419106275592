import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderSearchComponent } from './provider-search.component';
import { ProviderSearchRoutingModule } from './provider-search-routing.module';
import { AngularMaterialModule } from 'src/app/shared/angular-material/angular-material.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ProviderSearchComponent],
  imports: [ProviderSearchRoutingModule, AngularMaterialModule, CommonModule, SharedModule]
})
export class ProviderSearchModule { }
