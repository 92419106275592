import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchTypeEnum } from './search-form.model';
import { Option } from 'src/app/shared/classes/option';
import { toggleExpandAnimation } from 'src/app/shared/classes/animations';

export class SearchFormComponentData {
  formGroup = new UntypedFormGroup({});
  type = SearchTypeEnum.UNDEFINED;
  specialtiesTypeahead: Option[] = [];
  includeTeleHealth = false;
  showTaxToggle = false;

  constructor (formGroup: UntypedFormGroup, type: SearchTypeEnum, includeTeleHealth = false, showTaxToggle = false, specialtiesTypeahead = [] as Option[]) {
    this.formGroup = formGroup;
    this.type = type;
    this.includeTeleHealth = includeTeleHealth;
    this.showTaxToggle = showTaxToggle;
    this.specialtiesTypeahead = specialtiesTypeahead;
  }
}

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  animations: [toggleExpandAnimation]
})
export class SearchFormComponent implements OnInit {
  @Input() data: SearchFormComponentData | undefined;
  @Output() submitForm = new EventEmitter<any>();
  searchTypeEnum = SearchTypeEnum;
  showSpecialties = false;

  ngOnInit (): void {
    if (this.data != null) {
      this.configureFormControls();
    }
  }

  toggleSpecialties (event: MouseEvent): void {
    this.showSpecialties = !this.showSpecialties;
  }

  configureFormControls (): void {
    if (this.data?.type === SearchTypeEnum.TAXID) {
      const formGroup = this.data.formGroup;
      formGroup.addControl('taxId', new UntypedFormControl('', Validators.required));
      formGroup.addControl('address', new UntypedFormControl('', Validators.required));
    }

    if (this.data?.type === SearchTypeEnum.SPECIALTY) {
      const formGroup = this.data.formGroup;
      formGroup.addControl('specialty', new UntypedFormControl('', Validators.required));
      formGroup.addControl('address', new UntypedFormControl('', Validators.required));
    }

    if (this.data?.type === SearchTypeEnum.NAME) {
      const formGroup = this.data.formGroup;
      formGroup.addControl('facility', new UntypedFormControl('', Validators.required));
      formGroup.addControl('address', new UntypedFormControl('', Validators.required));
    }
  }

  onSearch (): void {
    this.submitForm.emit(this.data?.type);
  }
}
