import { Component } from '@angular/core';
import { SearchFilterBaseComponent } from '../search-base-filter.component';
import { Option } from 'src/app/shared/classes/option';

@Component({
  selector: 'search-input-filter',
  templateUrl: './search-input-filter.component.html',
  styleUrls: ['./search-input-filter.component.scss']
})
export class SearchInputFilterComponent extends SearchFilterBaseComponent<Option> {}
