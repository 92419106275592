<div class="container-fluid faq__wrapper">
    <ng-container *ngIf="isLoading">
        <anthem-skeleton-template [template]="skeletonTemplateEnum.FAQ"></anthem-skeleton-template>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div class="faq__header row">
            <div class="col-12 d-flex justify-content-between">
                <h4 class="faq__header--title ms-5 my-auto d-inline-flex align-items-center"><img class="me-2" src="../../../assets/Svg/Anthem_Help_FAQ_Panel_Icon.svg" alt="Help Image me-2">Help and FAQ</h4>
                <div class="faq__header--closeBtn me-4 my-auto d-inline-flex align-items-center">
                    <a href="javascript:;" (click)="closeMenu()"><img src="../../../assets/Svg/Anthem_Panel_Close_Icon.svg" alt="close icon"></a>
                </div>
            </div>
        </div>
        <div class="row faq__content mt-5 ms-5" *ngFor="let item of result | keyvalue; let i = index">
            <div *ngIf="i !== 0" class="row mt-5">
                <div class="col-12">
                <h2 class="faq__content--section-title">{{item.key}}</h2>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <mat-accordion>
                        <ng-container *ngFor="let info of item.value">
                            <mat-expansion-panel class="mat-elevation-z0">
                                <mat-expansion-panel-header>
                                 <h1 class="faq__content--header mt-3 mb-3" [innerHTML]=" getSanitizedText(info.header)"></h1>
                                </mat-expansion-panel-header>
                              
                                <ng-template matExpansionPanelContent>
                                   <p class="faq__content--text mt-3 p5 p5--regular" *ngFor="let entry of info.entries" [innerHTML]="getSanitizedText(entry)"></p>
                                </ng-template>
                              </mat-expansion-panel>
                        </ng-container>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </ng-container>
</div>
