import { NgModule } from '@angular/core';
import { ProviderResultsComponent } from './provider-results.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{ path: '', component: ProviderResultsComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderResultsRoutingModule { }
