<form class="form" [formGroup]="parentForm">
    <div class="filter-control-wrapper ">
        <div class="filter-control-wrapper__header">
            <div class="d-flex justify-content-between"> 
                <h3 class="my-auto">{{label}}</h3>
                <mat-icon class="d-sm-block d-md-none" (click)="toggle()">keyboard_arrow_down</mat-icon>
            </div>
            <span class="selected-values d-sm-block d-md-none">{{getValues()}}</span>
        </div>
        <div class="filter-control-wrapper__content" [(ngbCollapse)]="isCollapsed">
            <mat-radio-group #filter formControlName="{{id}}">
                <mat-radio-button color="primary" class="d-flex flex-column p5" *ngFor="let option of options" [value]="option.displayId">
                    {{option.displayName}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

    </div>
</form>