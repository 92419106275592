import { EventEmitter, Injectable } from '@angular/core';
import { SearchRequest } from '../provider-search/provider-search.model';
import { AppStateService } from '../app-state.service';

export const SavedStates = {
  SearchRequest: 'SearchRequest',
  ViewOptions: 'ViewOptions'
} as const;

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  clearFilterEmitter = new EventEmitter<string>();
  setFilterEmitter = new EventEmitter<any>();

  searchRequest: SearchRequest = {} as SearchRequest;

  constructor (private readonly appStateService: AppStateService) {
    const savedRequest = this.appStateService.loadState(SavedStates.SearchRequest, this.searchRequest);
    if (savedRequest !== null) this.searchRequest = savedRequest;
  }

  setSearchRequestOptions (data: SearchRequest): void {
    this.appStateService.saveState(SavedStates.SearchRequest, data);
    this.searchRequest = data;
  }

  getSearchRequest (): SearchRequest {
    return this.searchRequest;
  }
}
