<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-11 col-sm-12 text-center">
                <div class="row">
                    <div class="footer__links p4">
                        <nav>
                            <ul>
                                <li><a target="_blank" class="p4" href="https://www.anthemwc.com/terms_of_use.asp">Terms of Use</a></li>
                                <li><a target="_blank" class="p4" href="https://www.anthemwc.com/privacy_statement.asp">Privacy Statement</a></li>
                                <li><a target="_blank" class="p4" routerLink="disclaimer">Disclaimer</a></li>
                                <li><a target="_blank" class="p4" href="https://www.elevancehealth.com/newsroom">Press Room</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="row mb-2 mt-2">
                    <div class="footer__copyright mt-5">
                        <p class="p4">&copy; 2024 Anthem Workers Compensation. All rights Reserved.</p>
                    </div>
                </div>
                <div class="row">
                    <div class="footer__text col-11 mx-auto">
                        <p class="p4">Anthem Workers’ Compensation℠ (Anthem) is a product of Anthem Workers’
                            Compensation℠, LLC., a Limited Liability
                            Corporation. Anthem accesses independently contracted provider networks through affiliated
                            and other companies,
                            which it makes available by contract to payors of workers’ compensation benefits. Anthem has
                            no control or right of
                            control over the professional, medical judgment of independently contracted providers, and
                            is not liable for any
                            acts or failures to act, by independently contracted providers. Anthem is not an insurance
                            company and has no
                            liability for benefits under benefit plans offered or administered by employers or other
                            payors. Anthem Workers’
                            Compensation℠ is a service mark of Anthem Workers’ Compensation, LLC. Any reference in the
                            material to other
                            organizations or companies, including their Internet sites, is not an endorsement or
                            warranty of the services,
                            information or products provided by those organizations or companies.

                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-1 mt-5 mt-lg-0">
                <div class="footer__logo text-center">
                    <img src="../../../assets/Svg/Anthem_Perspecta_Logo.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</footer>