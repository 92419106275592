import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'urlLinkPipe' })
export class UrlLinkPipe implements PipeTransform {
  constructor (private readonly _sanitizer: DomSanitizer) {}

  private readonly regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_~#?&//=]*)?(\[.*\])?[?&]q=[^&]+/;

  transform (value: string): string | null {
    if (value.indexOf('http') > 0) {
      const link = value.match(this.regex);

      if (link != null) {
        const linkValues = link[0].split('[');
        const url = linkValues[0];
        const urlText = linkValues[1]?.slice(0, -1);
        value = value.replace(link[0], `<a href='${url}' target='_blank'>${urlText.length > 0 ? urlText : url}</a>`);
      }
    }

    return this._sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
