import { NoFunctions } from 'src/app/shared/classes/ts-core';

export class SearchRequest {
  searchLocation = '';
  searchType = 'Specialty';
  radius = 0;

  constructor (init?: Partial<Pick<SearchRequest, NoFunctions<SearchRequest>>>) {
    Object.assign(this, init);
  }
}
