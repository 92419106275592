import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AnthemRoutingModule } from './anthem-routing.module';
import { SharedModule } from '../shared/shared.module';
import { GlobalFaqComponent } from './global-faq/global-faq.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';

@NgModule({
  imports: [
    AnthemRoutingModule,
    SharedModule
  ],
  declarations: [HomeComponent, HeaderComponent, FooterComponent, GlobalFaqComponent, DisclaimerComponent]
})
export class AnthemModule { }
