import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfig } from '../dialog-base';

export interface IMessageDialogOptions {
  title: string
  message: string
  buttons: IDialogButton[]
}

interface IDialogButton {
  name: string
  value: number
}

export enum MessageDialogButtonValueEnum {
  OK = 0,
  CANCEL
}

export class OkDialogButton implements IDialogButton {
  name = 'Ok';
  value = MessageDialogButtonValueEnum.OK;
}

export class CancelDialogButton implements IDialogButton {
  name = 'Cancel';
  value = MessageDialogButtonValueEnum.CANCEL;
}

@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {
  constructor (public dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: IMessageDialogOptions) {}

  static dialogId = 'message_dialog';

  static showDialog (dialog: MatDialog, data: IMessageDialogOptions): MatDialogRef<MessageDialogComponent, any> {
    const dialogConfig = new DialogConfig(MessageDialogComponent.dialogId, 500, -1, data, false);
    return dialog.open(MessageDialogComponent, dialogConfig);
  }

  onButtonClicked (value: MessageDialogButtonValueEnum): void {
    this.dialogRef.close(value);
  }
}
