import { Component, OnInit } from '@angular/core';
import { GlobalFaqService } from './global-faq.service';
import { HelpInformation } from './global-faq.model';
import { ServiceResponse } from 'src/app/shared/classes/service-response';
import { CommonFunctions } from 'src/app/shared/helpers/common-functions';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SkeletonTemplateEnum } from 'src/app/shared/skeletons/skeleton-template.component';

@UntilDestroy()
@Component({
  selector: 'anthem-global-faq',
  templateUrl: './global-faq.component.html',
  styleUrls: ['./global-faq.component.scss']
})
export class GlobalFaqComponent implements OnInit {
  result = new HelpInformation();
  commonFunctions = new CommonFunctions();
  public isLoading = true;
  public skeletonTemplateEnum = SkeletonTemplateEnum;

  constructor (private readonly faqService: GlobalFaqService, private readonly _santizer: DomSanitizer) { }

  ngOnInit (): void {
    this.isLoading = true;
    this.faqService.loadHelpInformation().pipe(untilDestroyed(this)).subscribe((result: ServiceResponse<HelpInformation>) => {
      if (result.isSuccess) {
        this.result = result.result;
        this.isLoading = false;
      }
    });
  }

  closeMenu (): void {
    this.faqService.toggleFaqMenu(false);
  }

  getSanitizedText (html: string): string | null {
    return this.commonFunctions.sanitizeHtml(html, this._santizer);
  }
}
