import { trigger, transition, style, group, animate, query } from '@angular/animations';

export const toggleExpandAnimation = trigger('slideContentAnimation', [
  transition(':enter', [
    // we set the height of the outer container to 0, and
    // hide the overflow
    style({ height: '0px', overflow: 'hidden' }),
    group([
      // we animate the outer container height to it's
      // original value
      animate('250ms ease-out', style({ height: '!' })),
      // in the same time we translate the inner element all the
      // way from top to bottom
      query('.animated', [
        style({ transform: 'translatey(-110%)' }),
        group([
          animate('250ms ease-out',
            style({ transform: 'translatey(-0%)' }))
        ])
      ])
    ])
  ]),
  transition(':leave', [
    style({ overflow: 'hidden' }),
    group([
      animate('250ms ease-out', style({ height: '0' })),
      query('.animated', [
        style({ transform: 'translatey(-0%)' }),
        group([
          animate('250ms ease-out',
            style({ transform: 'translatey(-110%)' }))
        ])
      ])
    ])
  ])
]);
