import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CheckBoxSelectedArgs, CheckboxOption } from '../../classes/option';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'search-base',
  template: '',
  styles: [
  ]
})

export class SearchFilterBaseComponent<T> {
  @Input() parentForm = new UntypedFormGroup({});
  @Input() id = '';
  @Input() placeHolder = '';
  @Input() label = '';
  @Input() options: T[] = [];
  @Input() isCollapsed = false;
  @Output() toggleClicked = new EventEmitter<void>();
  @Output() optionSelected = new EventEmitter<CheckBoxSelectedArgs>();

  toggle (): void {
    this.toggleClicked.emit();
  }

  onClear (): void {
    this.parentForm.get(this.id)?.setValue('');
  }

  onSelected (event: MatCheckboxChange, option: CheckboxOption): void {
    this.optionSelected.emit(new CheckBoxSelectedArgs(event, option));
  }

  isChecked (val: string): boolean {
    const control = <UntypedFormArray> this.parentForm?.get(this.id);
    return control.value.includes(val);
  }

  showError (): boolean {
    const control = this.parentForm?.get(this.id);
    return !!((control?.invalid && control?.touched));
  }
}
