import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, map } from 'rxjs';
import { PublicLoginRequest } from '../auth.model';
import { HttpClientBaseService } from '../../shared/services/http-client-base.service';
import { AppHttpClientService } from '../../shared/misc/app-http-client/app-http-client.service';
import { ServiceResponse } from 'src/app/shared/classes/service-response';
import { UserCredentialsService } from './user-credentials.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpClientBaseService {
  private readonly auth = new ReplaySubject<boolean>(1);
  _auth$ = this.auth.asObservable();

  constructor (private readonly appHttp: AppHttpClientService, private readonly userCredentialsService: UserCredentialsService) {
    super('/api/v1/account');
  }

  loginAsPublicUser (creds: PublicLoginRequest): Observable<ServiceResponse<string>> {
    console.log('sending creds to endpoint for token', creds);
    return this.appHttp.post<ServiceResponse<string>>(this.buildUrl('/login'), creds, false, HttpClientBaseService.jsonOptions).pipe(map((res) => {
      this.auth.next(true);
      this.userCredentialsService.setToken(res.result);
      return res;
    }));
  }
}
