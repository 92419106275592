<div class="toaster" *ngFor="let toast of toasts">
    <ngb-toast 
    [animation]="true" 
    [ngClass]="toastClass.get(toast.type)" 
    [autohide]="toast.autoHide" 
    [delay]="toast.delay"
    (hidden)="removeNotification(toast)">
    <div class="d-flex">
        <div class="toast-body">
        {{toast.message}}
       </div>
        <button type="button" class="btn-close me-2 m-auto" (click)="removeNotification(toast)" aria-label="Close"></button>
      </div>
    </ngb-toast>
</div>
