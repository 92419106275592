import { NgModule } from '@angular/core';
import { AdvancedSearchComponent } from './advanced-search.component';
import { AdvancedSearchRoutingModule } from './advanced-search-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [AdvancedSearchComponent],
  imports: [AdvancedSearchRoutingModule, SharedModule]
})
export class AdvancedSearchModule { }
