<section class="page">
    <!-- Header  -->
    <div class="container-fluid">
        <div class="row page__header--gray page__header--border-top justify-content-center position-relative pt-3 pb-3">
            <div class="col-lg-10 col-sm-12 d-flex">
                <p class="my-auto p3"><a href="javascript:;" (click)="navigateToUrl()" class="button-text button-text--blue my-auto"><mat-icon class="align-middle">keyboard_arrow_left</mat-icon>{{linkText}}</a></p>
                <ng-container *ngIf="headerName !== ''">
                    <h4  class="heading-primary mx-auto my-auto">{{headerName}}</h4>
                </ng-container>
                <h4  *ngIf="temp"  class="heading-primary mx-auto my-auto"><ng-container [ngTemplateOutlet]="temp"></ng-container></h4>
            </div>
        </div>
    </div>
</section>
