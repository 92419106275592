<header class="header row justify-content-between">
  <button class="navbar-toggler d-md-none col-2" type="button" (click)="isMenuCollapsed = !isMenuCollapsed"><img
      src="../../../assets/Svg/Anthem_Header_Nav_Icon.svg" alt="Hamburger Icon"></button>
  <div class="header__icon-box d-none d-md-block my-auto col-2 col-xxl-2">
    <a href="javascript:;" (click)="navigateHome()"><img src="../../../assets/Svg/Anthem_Home_Icon.svg" alt="Home Icon"
        class="header__svg-icon"></a>
    <a href="javascript:;" (click)="showFaq()"><img src="../../../assets/Svg/Anthem_Help_FAQ_Icon.svg" alt="Faq Icon"
        class="header__svg-icon"></a>
  </div>
  <div class="header__provider-logos d-flex col-6 col-xxl-5 justify-content-center">
    <div *ngIf="userConfigService.clientLogo() !== ''" class="header__client-logo">
      <img [src]="userConfigService.clientLogo()" alt="Client Logo">
    </div>
  </div>
  <!-- <div *ngIf="userConfigService.headerLogo() !== ''"
    class="header__main-logo-box col-4 col-xxl-5 d-flex justify-content-end">
    <img [src]="userConfigService.headerLogo()" alt="Anthem Logo" class="header__main-logo">
  </div> -->
</header>
<div *ngIf="userConfigService.networkName() !== ''" class="row text-center network-name justify-content-center">
  <div class="col-10">
    <p class="network-name--text my-auto p3 p3--bold">{{userConfigService.networkName()}}</p>
  </div>

</div>

<div class="overlay" [ngStyle]="!isMenuCollapsed? {width: '100%', opacity: '1'} : {}">
  <div class="overlay__header row g-0">
    <div class="overlay__header-content col-12 d-flex justify-content-between my-auto">
      <div class="title">
        <h4>Menu</h4>
      </div>
      <div class="actions"><a (click)="isMenuCollapsed = !isMenuCollapsed"><mat-icon>close</mat-icon></a></div>
    </div>
  </div>
  <div class="overlay__content">
    <ul class="flex-column col-12">
      <li class="menu-item"><a class="pb-4" [routerLink]="'/provider-search'"
          (click)="isMenuCollapsed = !isMenuCollapsed"><i
            class="material-icons align-middle">home</i><span>Home</span></a></li>
      <li class="menu-item"><a class="pb-4 pt-4" (click)="showFaq()"> <i class="material-icons align-middle">help</i>
          <span>Help and FAQ</span></a></li>
    </ul>
  </div>
</div>