import { NgModule } from '@angular/core';
import { ProviderResultsComponent } from './provider-results.component';
import { ProviderResultsRoutingModule } from './provider-results-routing.module';
import { ProviderResultsService } from './provider-results.service';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ProviderResultsComponent],
  imports: [ProviderResultsRoutingModule, SharedModule],
  providers: [
    ProviderResultsService
  ]
})
export class ProviderResultsModule { }
