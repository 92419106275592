import { ChangeDetectorRef, Component } from '@angular/core';

export interface ISearchValueConstraint {
  value: string
  canRemove: boolean
}

export enum SearchOptionsEnum {
  PRACTICE,
  STATE,
  DISTANCE,
  SPECIALTIES

}

@Component({
  selector: 'search-breadcrumb-filter',
  templateUrl: './search-breadcrumb-filter.component.html'
})
export class SearchBreadcrumbFilterComponent {
  constructor (private readonly cd: ChangeDetectorRef) {}

  activeFilters = new Map<SearchOptionsEnum, ISearchValueConstraint>();

  setActiveFilters (activeFilters: Map<SearchOptionsEnum, ISearchValueConstraint>): void {
    this.sortFilters(activeFilters);
    this.cd.detectChanges();
  }

  addFilter (key: SearchOptionsEnum, value: ISearchValueConstraint): void {
    this.activeFilters.set(key, value);
    this.sortFilters(this.activeFilters);
    this.cd.detectChanges();
  }

  clearFilter (removedFilter: SearchOptionsEnum): void {
    this.activeFilters.delete(removedFilter);
  }

  sortFilters (filters: Map<SearchOptionsEnum, ISearchValueConstraint>): void {
    const elements = Array.from(filters).sort((x, y) => {
      return x[1].canRemove ? 1 : -1; // `false` values first
    });
    this.activeFilters = new Map(elements);
  }

  returnZero (): number { return 0; }
}
