export enum LoggerSwitchEnum {
  OFF = 0,
  ON = 1
}

export enum LoggerLevel {
  DEBUG = 0,
  LOG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4
}
