import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AnthemModule } from './anthem/anthem.module';
import { appRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInteceptor } from './shared/inteceptors/http-error.inteceptor';
import { LoggerService } from './shared/services/logger/logger.service';
import { GlobalFaqService } from './anthem/global-faq/global-faq.service';
import { ToastService } from './shared/components/dialogs/toast/toast.service';
import { AuthenticationService } from './auth/services/authentication.service';
import { PublicLoginRequest } from './auth/auth.model';
import { UserCredentialsService } from './auth/services/user-credentials.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { environment } from '../environments/environment';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AnthemModule,
    SharedModule,

    RouterModule.forRoot(appRoutes, { enableTracing: false })
  ],
  providers: [
    GlobalFaqService,
    LoggerService,
    ToastService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInteceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },
    { provide: APP_INITIALIZER, useFactory: initializeAppPublicLogin, multi: true, deps: [AuthenticationService, LoggerService, UserCredentialsService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeAppPublicLogin (authService: AuthenticationService, logger: LoggerService, userCredentialsService: UserCredentialsService): () => Promise<void> {
  return async () => {
    await new Promise<void>((resolve) => {
      if (!userCredentialsService.isUserAuthenticated()) {
        logger.log('logging public user into app');
        const credentials = new PublicLoginRequest();
        credentials.password = environment.password;
        credentials.userName = environment.userName;

        authService.loginAsPublicUser(credentials).subscribe((result) => {
          logger.log(result.message);
          resolve();
        });
      } else {
        resolve();
      }
    });
  };
}
