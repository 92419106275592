<div [formGroup]="parentForm" class="position-relative">
    <input class="form__input" type="text" #autoComplete  placeholder="Specialty" formControlName="{{id}}" [matAutocomplete]="auto" class="form__input" (blur)="onBlur($event)" (input)="onInput($event)">
    <mat-icon *ngIf="autoComplete.value.length > 0" (click)="onClear()" class="input-icon" svgIcon="clearInputIcon" matSuffix></mat-icon>
    <mat-icon *ngIf="parentForm.controls['specialty'].invalid && parentForm.controls['specialty'].touched" class="input-icon input-icon--error"  svgIcon="inputErrorIcon"  matSuffix></mat-icon>
    <span class="error-box" *ngIf="parentForm.controls['specialty'].invalid && parentForm.controls['specialty'].touched">{{errorMessages.getMessage(parentForm.controls['specialty'])}}</span>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let specialty of filteredStaticOptions | async" [value]="specialty.displayId">
          {{specialty.displayName}}
        </mat-option>
        <mat-option *ngIf="noMatches">No matches found</mat-option>
      </mat-autocomplete>
</div>